import React from "react"
import Layout from "../components/layout"
import { Container } from 'react-bootstrap'
import SEO from "../components/seo"

const ThankYouPage = () => (
  <Layout>
        <SEO title="Thank you" />
        <Container className="thankyou_st">
            <h1 className="header_title main_b_color">Your email has been successfully sent.</h1>
        </Container>
  </Layout>
)

export default ThankYouPage